const blockPostsSlide = (Swiper, Navigation, Autoplay) => {
    const block = '.js-swiper-posts-slide';
    const blockContainer = document.querySelector(block);
    if (!blockContainer) return false;
    let swiper = '';

    // const swiperSize = (width) => {
    // const pos = blockContainer.getBoundingClientRect();
    // blockContainer.style.marginRight = `${pos.x * -1}px`;
    // if (width > 1024) {
    // 	if (swiper) {
    // 		// swiper.autoplay.start();
    // 	}
    // } else if (width < 1024) {
    // 	blockContainer.style.marginRight = '0px';
    // }
    // };

    // const arrowPrev = blockContainer.querySelector('.js-arrow-prev');
    // const arrowNext = blockContainer.querySelector('.js-arrow-next');

    const swiperParams = {
        modules: [Autoplay, Navigation],
        pagination: false,
        // navigation: {
        // 	prevEl: arrowPrev,
        // 	nextEl: arrowNext,
        // },
        navigation: false,
        autoplay: false,
        slidesPerView: 1.2,
        disableOnInteraction: false,
        resizeObserver: false,
        loop: false,
        // enabled: false,
        spaceBetween: 16,

        breakpoints: {
            1024: {
                slidesPerView: 3,
                spaceBetween: 24,
                // enabled: true,
            },
        },
        // on: {
        // 	update: () => {
        // 		const windowW = window.innerWidth;
        // 		swiperSize(windowW);
        // 	},
        // 	resize: () => {
        // 		const windowW = window.innerWidth;
        // 		swiperSize(windowW);
        // 	},
        // },
    };

    swiper = new Swiper(block, swiperParams);

    return swiper;
};
export default blockPostsSlide;
