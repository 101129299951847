const blockCardsSlide = (Swiper, Navigation, Autoplay) => {
    const block = '.js-swiper-cards-slide';
    const blockContainer = document.querySelector(block);
    if (!blockContainer) return false;
    let swiper = '';

    const arrowPrev = blockContainer.querySelector('.js-arrow-prev');
    const arrowNext = blockContainer.querySelector('.js-arrow-next');

    const swiperParams = {
        modules: [Navigation, Autoplay],
        pagination: false,
        navigation: {
            prevEl: arrowPrev,
            nextEl: arrowNext,
        },
        autoplay: false,
        slidesPerView: 1.1,
        spaceBetween: 20,
        disableOnInteraction: false,
        loop: true,
        breakpoints: {
            768: {
                slidesPerView: 1.5,
            },
            1024: {
                slidesPerView: 2.5,
                spaceBetween: 24,
            },
            1500: {
                slidesPerView: blockContainer.dataset.slides,
                spaceBetween: 24,
            },
        },
    };

    swiper = new Swiper(block, swiperParams);

    return swiper;
};
export default blockCardsSlide;
