const blockHero = () => {
    const videos = document.querySelectorAll('[data-hero-video]');

    if (!videos) return;

    const options = {
        threshold: 0.5,
    };

    const observer = new IntersectionObserver((els) => {
        els.forEach((el) => {
            const item = el;
            if (item.isIntersecting) {
                item.target.play();
                item.target.currentTime = 0;
            } else {
                item.target.pause();
            }
        });
    }, options);

    videos.forEach((el) => {
        const video = el;
        observer.observe(video, options);
    });
};

export default blockHero;
