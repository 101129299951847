const blockServices = () => {
    const servicesNumbers = document.querySelectorAll('[data-services-up]');
    const servicesObserverConfig = {
        root: null,
        rootMargin: '0px',
        threshold: 1,
    };

    const servicesObserver = new IntersectionObserver((els) => {
        els.forEach((el) => {
            if (el.isIntersecting) {
                el.target.classList.add('active');
                servicesObserver.disconnect();
            }
        });
    }, servicesObserverConfig);

    servicesNumbers.forEach((number) => {
        servicesObserver.observe(number);
    });
};

export default blockServices;
