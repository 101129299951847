const blockTestimonials = (Swiper, Navigation, Pagination, Autoplay) => {
    const block = '.js-swiper-testimonials';
    if (!document.querySelector(block)) return false;

    const swiper = new Swiper(block, {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        autoplay: false,
    });
    return swiper;
};
export default blockTestimonials;
