const blockFlipCards = () => {
    const blocks = document.querySelectorAll('[data-flip-cards]');

    if (!blocks) return;

    blocks.forEach((el) => {
        const block = el;

        const cards = block.querySelectorAll('[data-flip-cards-front]');

        cards.forEach((elChild) => {
            const card = elChild;
            const height = card.offsetHeight;
            card.parentElement.style.minHeight = `${height}px`;

            const btn = card.querySelector('[data-flip-cards-btn]');

            btn.onclick = () => {
                card.parentElement.classList.toggle('active');
            };

            card.nextElementSibling.onclick = () => {
                card.parentElement.classList.toggle('active');
            };
        });
    });
};

export default blockFlipCards;
