const videoAction = (block) => {
    const videos = block.querySelectorAll('[data-carousel-video]');

    if (!videos) return;

    const options = {
        threshold: 0.5,
    };

    const observer = new IntersectionObserver((els) => {
        els.forEach((el) => {
            const item = el;
            if (item.isIntersecting && item.target.dataset.carouselVideo === 'first') {
                item.target.play();
                item.target.currentTime = 0;
            } else {
                item.target.pause();
                item.target.classList.remove('opacity');
            }
        });
    }, options);

    videos.forEach((el) => {
        const video = el;
        observer.observe(video, options);

        video.onended = () => {
            if (videos.length > 1) {
                video.classList.add('ended', 'opacity');
                video.currentTime = 0;
            }

            const next = block.querySelector('[data-carousel-video]:not(.ended)');
            if (!next) return;
            next.classList.remove('opacity');
            next.play();
        };

        video.onmouseenter = () => {
            videos.forEach((target) => {
                const opacity = target;

                if (videos.length > 1) {
                    opacity.classList.remove('opacity');
                }

                if (video !== opacity) {
                    if (videos.length > 1) {
                        opacity.classList.add('opacity');
                        opacity.pause();
                    }
                }

                video.play();
                // const promise = video.play();
                // if (promise !== undefined) {
                // 	promise
                // 		.then(() => {
                // 			if (video !== opacity) {
                // 				console.log('damian');
                // 				video.muted = true;
                // 			}
                // 			video.muted = false;
                // 		})
                // 		.catch((er) => {
                // 			console.warn(er);
                // 		});
                // }
            });
        };
    });
};

const blockCarouselVideos = (Swiper, Navigation) => {
    const block = '.js-block-carousel-videos';
    const blockContainer = document.querySelector(block);

    if (!blockContainer) return false;
    let swiper = '';

    const arrowPrev = blockContainer.querySelector('.js-arrow-prev');
    const arrowNext = blockContainer.querySelector('.js-arrow-next');

    const swiperParams = {
        modules: [Navigation],
        pagination: false,
        navigation: {
            prevEl: arrowPrev,
            nextEl: arrowNext,
        },
        autoplay: false,
        slidesPerView: 2,
        spaceBetween: 30,
        disableOnInteraction: false,
        loop: false,
        centeredSlides: blockContainer.dataset.center === 'true' ?? true,
        breakpoints: {
            // 768: {
            // 	slidesPerView: 3,
            // 	spaceBetween: 60,
            // },
            992: {
                slidesPerView: 4,
            },
        },
    };

    swiper = new Swiper(block, swiperParams);

    videoAction(blockContainer);

    return swiper;
};

export default blockCarouselVideos;
