const blockTeam = (Swiper, Navigation, Autoplay) => {
    const block = '.js-swiper-team';
    if (!document.querySelector(block)) return false;

    const swiper = new Swiper(block, {
        modules: [Navigation, Autoplay],
        pagination: false,
        navigation: {
            nextEl: '.js-swip-next-team',
            prevEl: '.js-swip-prev-team',
        },
        autoplay: false,
        slidesPerView: 1,
        resizeObserver: false,
        loop: false,
        spaceBetween: 0,
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 60,
            },
        },
    });
    return swiper;
};
export default blockTeam;
