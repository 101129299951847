const animatedLoopImg = () => {
    const parentAnim = document.querySelectorAll('.js-animated-loop-parent');

    if (parentAnim) {
        parentAnim.forEach((parent) => {
            const images = parent.querySelectorAll('.js-animated-loop');
            if (images) {
                const countImage = images.length;
                setInterval(function () {
                    const nextTarget = parent.querySelector('.js-animated-loop.show');
                    images.forEach((el) => el.classList.remove('show'));

                    images.forEach((el, key) => {
                        if (nextTarget === el) {
                            if (key + 2 === countImage) images[0].classList.add('show');
                            else images[key + 1].classList.add('show');
                        }
                    });
                }, 900);
            }
        });
    }
};

export default animatedLoopImg;
