const actionForm = () => {
    const popups = document.querySelectorAll('.js-popup-content');
    const closePopup = document.querySelectorAll('.js-close-popup');
    const textarea = document.querySelectorAll('.wpcf7-textarea');

    if (!popups) return;

    if (textarea) {
        textarea.forEach((el) => {
            el.addEventListener(
                'input',
                function () {
                    this.style.height = 'auto';
                    this.style.height = `${this.scrollHeight}px`;
                },
                false,
            );
        });
    }

    document.addEventListener(
        'wpcf7mailsent',
        (e) => {
            const targetEl = e.target.closest('.js-form-target');
            if (targetEl) {
                const popupId = targetEl.getAttribute('data-targetMessage');
                const elPopupTarget = document.getElementById(popupId);
                if (elPopupTarget) elPopupTarget.classList.add('active');
            }
        },
        false,
    );

    if (closePopup && popups) {
        closePopup.forEach((btn) =>
            btn.addEventListener('click', () => popups.forEach((el) => el.classList.remove('active'))),
        );
    }
};

export default actionForm;
