/*
  Project: Zima
  Author: WebCrafters Studio
 */

import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
// import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
import AOS from 'aos';
// import scrollTo from './modules/scrollto';
import blockTestimonials from './modules/block-testimonials';
import blockPostsSlide from './modules/block-posts-slide';
import blockCardsSlide from './modules/block-cards-slide';
import blockTeam from './modules/block-team';
import blockServices from './modules/block-services';
import blockLogosSlider from './modules/block-logos-slider';
import blockAccordion from './modules/block-accordion';
import actionForm from './modules/block-form';
import animatedLoopImg from './modules/animated-loop-img';
import blockHero from './modules/block-hero';
import blockCarouselVideos from './modules/block-carousel-videos';
import blockFlipCards from './modules/block-flip-cards';

document.addEventListener('DOMContentLoaded', () => {
    // open,close menu
    const megaMenu = document.querySelector('.c-mega-menu');
    const closeMenu = document.querySelector('.js-close-menu');
    const openMenu = document.querySelector('.js-open-menu');
    const header = document.getElementById('js-header');

    if (closeMenu)
        closeMenu.addEventListener('click', () => {
            megaMenu.classList.remove('active');
            header.classList.remove('open-menu');
        });

    if (openMenu)
        openMenu.addEventListener('click', () => {
            megaMenu.classList.add('active');
            header.classList.add('open-menu');
        });

    // Back Top
    const backTop = document.getElementById('js-back-top');
    if (backTop) {
        backTop.onclick = (e) => {
            e.preventDefault();
            const anchor = document.querySelector('body');
            if (!anchor) return;
            window.scrollTo({ top: 0 });
        };
    }
    // Header
    if (header) {
        const doc = document.documentElement;
        const w = window;

        let prevScroll = w.scrollY || doc.scrollTop;
        let curScrollb;
        let directionb = 0;
        let prevDirection = 0;

        const toggleHeader = (direction, curScroll) => {
            let startScroll = 100;
            if (window.matchMedia('(max-width: 650px)')) startScroll = 70;

            if (direction === 2 && curScroll > startScroll) {
                header.classList.add('header-hidden');
                header.classList.remove('scrolled');
                prevDirection = direction;
            } else if (direction === 1) {
                header.classList.remove('header-hidden');
                header.classList.add('scrolled');
                prevDirection = direction;
            }
        };

        window.onscroll = () => {
            // const top = window.scrollY;
            let startScrollsec = 100;
            if (window.matchMedia('(max-width: 650px)')) startScrollsec = 70;

            curScrollb = w.scrollY || doc.scrollTop;
            if (curScrollb > prevScroll) directionb = 2;
            else if (curScrollb < prevScroll) directionb = 1;

            if (directionb !== prevDirection) toggleHeader(directionb, curScrollb);

            if (curScrollb < startScrollsec) header.classList.remove('scrolled');

            // if (top > 100) {
            // 	header.classList.add('scrolled');
            // 	return;
            // }
            // header.classList.remove('scrolled');

            prevScroll = curScrollb;
        };
    }
    // Footer
    const footerMenu = document.querySelectorAll('.js-footer-menu');
    if (footerMenu) {
        footerMenu.forEach((el) => {
            const menu = el;
            menu.onclick = () => {
                menu.classList.toggle('active');
                menu.nextElementSibling.classList.toggle('active');
            };
        });
    }
    // Copy email
    const emailCopy = document.querySelectorAll('[data-email]');
    if (emailCopy) {
        emailCopy.forEach((el) => {
            const email = el;
            email.onclick = (e) => {
                e.preventDefault();
                if (el.classList.contains('active') || el.classList.contains('error')) return;
                navigator.clipboard.writeText(email.dataset.email).then(
                    () => {
                        el.classList.toggle('active');
                        setTimeout(() => {
                            el.classList.toggle('active');
                        }, 1500);
                    },
                    (err) => {
                        const catchErr = err;
                        console.error(catchErr);
                        el.classList.toggle('error');
                        setTimeout(() => {
                            el.classList.toggle('error');
                        }, 1500);
                    },
                );
            };
        });
    }

    blockTestimonials(Swiper, Navigation, Pagination, Autoplay);
    blockPostsSlide(Swiper, Navigation, Autoplay);
    blockCardsSlide(Swiper, Navigation, Autoplay);
    blockTeam(Swiper, Navigation, Autoplay);
    blockServices();
    blockLogosSlider(Swiper, Navigation, Autoplay);
    blockCarouselVideos(Swiper, Navigation);
    blockAccordion();
    blockHero();
    blockFlipCards();
    actionForm();
    animatedLoopImg();

    if (typeof AOS !== 'undefined') {
        AOS.init({
            duration: 1000,
            disable: 'mobile',
        });
    }
    // Block - Thank you
    document.addEventListener(
        'wpcf7mailsent',
        (event) => {
            // eslint-disable-next-line no-restricted-globals
            location = event.target.closest('[data-redirect]').dataset.redirect;
        },
        false,
    );
    // ToDo module
    // gsap.registerPlugin(ScrollTrigger);
    // const heading = gsap.utils.toArray('.js-heading-moving');

    // heading.forEach((element) => {
    // 	const headingConfig = gsap.timeline({
    // 		defaults: {
    // 			duration: 2,
    // 			ease: true,
    // 		},
    // 		scrollTrigger: {
    // 			trigger: element,
    // 			start: 'top bottom',
    // 			end: 'top top',
    // 			// markers: true,
    // 			scrub: 0.1,
    // 		},
    // 	});

    // 	headingConfig
    // 		.fromTo(
    // 			element,
    // 			{
    // 				x: -window.innerWidth,
    // 			},
    // 			{
    // 				x: 0,
    // 			},
    // 			0,
    // 		)
    // 		.fromTo(
    // 			element,
    // 			{
    // 				x: 0,
    // 			},
    // 			{
    // 				x: window.innerWidth,
    // 				immediateRender: false,
    // 			},
    // 			2,
    // 		);
    // });

    // const headingObserverOptions = {
    // 	rootMargin: '500px',
    // 	threshold: 0,
    // };

    // const headingObserver = new IntersectionObserver((elements) => {
    // 	elements.forEach((el) => {
    // 		if (el.isIntersecting) {
    // 			ScrollTrigger.refresh();
    // 		}
    // 	});
    // }, headingObserverOptions);

    // heading.forEach((entry) => {
    // 	headingObserver.observe(entry.parentElement);
    // });
});
