const blockLogosSlider = (Swiper, Navigation, Autoplay) => {
    const block = '.js-swiper-logos-slide';
    const blockContainer = document.querySelector(block);
    if (!blockContainer) return false;
    let swiper = '';

    const arrowPrev = blockContainer.querySelector('.js-arrow-prev');
    const arrowNext = blockContainer.querySelector('.js-arrow-next');

    const swiperParams = {
        modules: [Navigation, Autoplay],
        pagination: false,
        navigation: {
            prevEl: arrowPrev,
            nextEl: arrowNext,
        },
        autoplay: false,
        slidesPerView: 2,
        spaceBetween: 16,
        disableOnInteraction: false,
        loop: false,
        breakpoints: {
            768: {
                spaceBetween: 24,
                slidesPerView: 3,
            },
            1360: {
                spaceBetween: 24,
                slidesPerView: 4,
            },
        },
    };

    swiper = new Swiper(block, swiperParams);

    return swiper;
};
export default blockLogosSlider;
